/* body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    height: 100vh;
    background: linear-gradient(#2a00b7, #42006c);
} */

.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    background: #fff;
    padding-bottom: 30px;
    width : 600px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text {
    color: rgb(100,100,120);
    font-size: 42px;
    font-weight: 700;
}

.underline {
    width: 61px;
    height: 6px;
    background: rgb(100,100,120);
    border-radius: 9px;
}

.inputs {
    margin-top: 55px;
    display: flex;
    flex-direction: column ;
    gap: 25px;
}

.input {
    display : flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}

.input img {
    margin: 0px 30px;
}

.input input {
    height : 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}
.forgot-password, .hav-account, .new-account {
    padding-left: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 18px;
}

.forgot-password span {
    color: #4c00b4;
    cursor: pointer;
}

.hav-account span {
    color: #4c00b4;
    cursor: pointer;
}

.new-account span {
    color: #4c00b4;
    cursor: pointer;
}

.submit-container {
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.submit {
    display : flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 59px;
    color: #fff;
    background: rgb(100,100,120);
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.gray {
    background: #eaeaea;
    color: #676767;
}