body {

  background: rgb(100,100,120);
  color: white;
  font-family: 'Poppins', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html {
  font-size: 62.5%;
}